import "../App.css";
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import numeral from "numeral";
import { topPlatforms, getSolanaStatus, fetchPrice } from '../server';
import SolanaDetails from "../hooks/SolanaDetails";
import SolanaStatus from "../utils/SolanaStatus";
import Dexs from "../utils/Dexs";

export default function Home() {
  const [progress, setProgress] = useState(0); // Initial progress at 0%
  const [platformData, setPlatformData] = useState([]);
  const [solanaStatus, setSolanaStatus] = useState("");
  const [solanaPrice, setSolanaPrice] = useState("");

  async function getPlatforms() {
    const top = await topPlatforms();
    setPlatformData(top?.platformsData.sort((a, b) => b.liquidity24h - a.liquidity24h))
  }

  async function fetchStatus() {
    const data = await getSolanaStatus();
    setSolanaStatus(data.data?.status.description)
  }

  async function getPrice() {
    const solanaPrice = await fetchPrice();
    setSolanaPrice(solanaPrice)
  }

  useEffect(() => {
    getPlatforms()
    fetchStatus()
    getPrice()
  }, []);

  useEffect(() => {
    setInterval(() => {
      setProgress(prevProgress => Math.min(prevProgress + 1, 100));
    }, 1000);
  }, []);

  useEffect(() => {
    if (progress >= 99) {
      getPlatforms()
      fetchStatus()
      getPrice()
      setProgress(0)
    }
  }, [progress])

  return (
    <div>
      <div className="loading-bar">
        <div className="progress" style={{ width: `${progress}%` }}></div>
      </div>

      <motion.div>
        <motion.h1 initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          Solana Pulse
        </motion.h1>
      </motion.div>

      <motion.h3>Leading Platforms</motion.h3>

      <Dexs platformData={platformData} />

      <motion.div initial={{ y: 200 }} animate={{ y: 0 }} className="homepage_data">
        <SolanaDetails textSize={"1.5rem"} text={"Solana Price"} solanaPrice={solanaPrice ? numeral(solanaPrice[0][0]?.current_price).format("$0,0.00") : 0} />
        <SolanaDetails text={"Marketcap"} solanaPrice={solanaPrice ? numeral(solanaPrice[0][0]?.market_cap).format("$0.0a") : 0} />

        <SolanaDetails text={"Low 24h"} solanaPrice={solanaPrice ? numeral(solanaPrice[0][0]?.low_24h).format("$0,0.00") : 0} />
        <SolanaDetails text={"High 24h"} solanaPrice={solanaPrice ? numeral(solanaPrice[0][0]?.high_24h).format("$0,0.00") : 0} />
      </motion.div>
    </div>
  )
}
