import "../styles/Navbar.css";
import React from 'react';
import { motion } from 'framer-motion';

import logo from "../assets/solana_pulse.svg";
// import left_arrow from "../assets/icons/left_arrow.svg";
// import right_arrow from "../assets/icons/right_arrow.svg";
import home_icon from "../assets/icons/home_icon.svg";

export default function Navbar(props) {
  return (
    <div className="Navbar">
      <div className="Logo">
        <img alt="logo" height={50} src={logo}></img>

        {props.enabled === true && (
          <div>Solana Pulse</div>
        )}
      </div>

      <div style={{ marginTop: "auto" }}>
        <motion.button
          whileHover={{ scale: 1.1 }}
          className="Navbar_button"
        >
          <img alt="" height={25} src={home_icon} />
        </motion.button>
      </div>

      <div style={{ marginTop: "auto" }}>
        <motion.button
          whileHover={{ scale: 1.1 }}
          className="Navbar_button"
        >
          <img alt="" height={25} src={home_icon} />
        </motion.button>
      </div>

      {/* <div style={{ marginTop: "auto", left: props.enabled === false ? 45 : 125, top: "75dvh", position: "relative" }}>
        <motion.button
          style={{ height: 35, width: 45 }}
          whileHover={{ scale: 1.1 }}
          className="Navbar_button"
          onClick={() => props.enableNavbar()}>
          {props.enabled === true ? (
            <img alt="" height={20} src={left_arrow} />
          ) : (
            <img alt="" height={20} src={right_arrow} />
          )}
        </motion.button>
      </div> */}
    </div>
  )
}
