import React from 'react';
import { motion } from 'framer-motion';
import numeral from "numeral";
import SlotCounter from "react-slot-counter"

export default function Dexs({ platformData }) {
  return (
    <motion.div initial={{ y: 200 }} animate={{ y: 0 }} className="homepage_data">
      <div style={{ background: "#414141", padding: ".5rem", borderRadius: "1rem", width: "100%" }}>
        {platformData?.length > 0 ? (
          <>
            <div className="Data_Grid">
              {platformData && platformData.map(platforms =>
                <motion.div className="Platform_data" initial={{ y: 200 }} animate={{ y: 0 }}>
                  {platforms.platform}

                  <div style={{ fontSize: "1.25rem" }}>
                    <SlotCounter
                      duration={1}
                      direction="top-down"
                      value={numeral(platforms.liquidity24h).format("$0,0")}
                      animateOnVisible={{ triggerOnce: false, rootMargin: '0px 0px -100px 0px' }}
                    />
                    {/* {numeral(platforms.liquidity24h).format("$0,0")} */}
                  </div>
                </motion.div>
              )}
            </div>
          </>
        ) : (
          <div>Loading Solana Platforms...</div>
        )}
      </div>
    </motion.div>
  )
}
