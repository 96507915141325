import axios from "axios";

export async function topPlatforms() {
  return axios.get("https://api.solanapulse.com/platforms").then((data) => {
    // console.log("Top Platforms")
    // console.log(data.data)
    return data?.data[0];
  })
}

export async function getSolanaStatus() {
  return axios.get("https://status.solana.com/api/v2/status.json").then((data) => {
    // console.log(data)
    return data;
  })
}

export async function fetchTopTokens() {
  return await axios.get("https://api.solanafloor.com/stats").then(async (data) => {
    // console.log(data.data)
    return data.data;
  })
}

export async function fetchOtherStats() {
  let dataArray = [];

  let fetchedTokens = await axios.get("https://api.step.finance/v2/markets/prices").then(async (data) => {
    dataArray.push(data.data)
    return data.data;
  })

  console.log("Fetching top array coins");
  for (let i = 0; i < dataArray.length; i++) {
    console.log("Data found")
    console.log(dataArray[i])
  }

  return fetchedTokens;
}

export async function fetchPrice() {
  return await axios.get("https://api.solanapulse.com/price").then((data) => {
    console.log("Fetching Solana Price")
    console.log(data.data)
    return data.data;
  })
}