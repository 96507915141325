import './App.css';
import { useState } from "react"
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Navbar from './components/Navbar';

function App() {
  const [showNavbar, setShowNavbar] = useState(false);

  return (
    <div className="App">
      <Navbar
        enabled={showNavbar}
        enableNavbar={() => setShowNavbar(!showNavbar)}
      />

      <div style={{ marginLeft: showNavbar === true ? 250 : 50 }}>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
