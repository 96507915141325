import React from 'react';
import SlotCounter from 'react-slot-counter';

export default function SolanaDetails(props) {
  return (
    <div style={{ width: "100%", minWidth: "100%", display: "flex", justifyContent: "space-between", fontSize: props?.textSize ? props?.textSize : "1rem" }}>
      <div>{props.text}</div>

      <div>
        <SlotCounter
          duration={1}
          direction="top-down"
          value={props.solanaPrice}
          animateOnVisible={{ triggerOnce: false, rootMargin: '0px 0px -100px 0px' }}
        />
      </div>
    </div>
  )
}
